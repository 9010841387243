import React, { useState, useEffect, useCallback } from 'react';
import FullPageWrapper from './FullPageWrapper';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'fullpage.js/dist/fullpage.min.css';
import './resources/loader.css'; // Assuming the path is correct

function App() {
  const [videos, setVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://xx.dreamx.red/listFiles.php')
     .then(response => response.json())
      .then(data => setVideos(data))
      .catch(error => setError(error));
  }, []);

  const playRandomVideo = useCallback(() => {
    if (videos.length === 0) {
      console.error('No videos available to play.');
      return;
    }

    const randomIndex = Math.floor(Math.random() * videos.length);
    const randomVideoUrl = videos[randomIndex];
    setCurrentVideo(randomVideoUrl);

    const videoElement = document.getElementById('background-video');
    if (videoElement) {
      videoElement.src = randomVideoUrl;
      videoElement.play();

      setTimeout(() => {
        videoElement.pause();
        const randomInterval = (Math.floor(Math.random() * 5) + 1) * 60000;
        setTimeout(playRandomVideo, randomInterval);
      }, 30000);
    }
  }, [videos]);

  useEffect(() => {
    if (videos.length > 0 && !error) {
      playRandomVideo();
    }
  }, [videos, error, playRandomVideo]);

  const togglePlayPause = () => {
    const videoElement = document.getElementById('background-video');
    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  };

  return (
    <FullPageWrapper
      licenseKey="5B85534B-48984A57-A38BE104-686ED59B"
      scrollingSpeed={1000}
      navigation={true}
      navigationPosition="right"
      navigationTooltips={[]}
    >
      <div className="section fp-section active fp-table fp-completely">
        <div className="fp-tableCell">
          <div id="wrapper">
            <div id="content">
              <div id="content-inside">
                <div id="cogs">
                  <div id="gear1" className="gear"></div>
                  <div id="gear2" className="gear"></div>
                </div>
                <h1>Welcome To DreamX</h1>
                <h2>
                  Your <span className="buldge">Worx</span> is up &amp; running like a <span className="buldge">Beast!</span>
                </h2>
                <button className="custom-btn btn">
                  <a href="https://dreamx.media/3w/">Enter</a>
                </button>
              </div>
            </div>
            <video
              id="background-video"
              className="video"
              type="application/octet-stream"
              autoPlay
              muted
              playsInline
              loop
              controlsList="nodownload"
              src={currentVideo}
              onClick={togglePlayPause}
            />
          </div>
        </div>
      </div>
    </FullPageWrapper>
  );
}

export default App;