import React from 'react';
import FullPage from 'react-fullpage';

const FullPageWrapper = ({ children, licenseKey, ...props }) => {
  return (
    <FullPage licenseKey={licenseKey} {...props}>
      {children}
    </FullPage>
  );
};

export default FullPageWrapper;
